function convertAnimation(startText, endText, elementId) {
  const exampleElement = document.getElementById(elementId);
  const newElement = exampleElement.cloneNode(true);
  newElement.textContent = startText;
  void newElement.offsetWidth;
  newElement.classList.add("run-animation");
  setTimeout(() => {
    newElement.textContent = endText;
    setTimeout(convertAnimation, 10000, startText, endText, elementId);
    newElement.classList.remove("run-animation");
  }, 5000);
  exampleElement.parentNode.replaceChild(newElement, exampleElement);
}
var lang = document.documentElement.lang;
var params = {
  en: {
    first: [
      "R$ 23,45",
      "R$ 23,45 (twenty three brazilian reais and forty five cents)",
      "convert-example",
    ],
    second: [
      "US$ 1,01",
      "US$ 1,01 (one dollar and one cent)",
      "convert-example-2",
    ],
  },
  pt: {
    first: [
      "R$ 23,45",
      "R$ 23,45 (vinte e três reais e quarenta e cinco centavos)",
      "convert-example",
    ],
    second: [
      "US$ 1,01",
      "US$ 1,01 (um dólar e um centavo)",
      "convert-example-2",
    ],
  },
};
convertAnimation(...params[lang].first);
convertAnimation(...params[lang].second);
